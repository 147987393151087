import * as ACTIONS from "../constants";

// Initial State
const participationInitialState = {
  error: null,
  success: null,
  isLoading: false,
  activities: [],
  users: [],
  item: null,
};

/**
 * Update participation store
 * @param  {} state: participationInitialState
 * @param  {} action
 */
const participationReducer = (state = participationInitialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_STORE: {
      return { ...state, error: null, success: null };
    }

    case ACTIONS.ADD_PARTICIPATION_INIT: {
      return { ...state, isLoading: true, success: false, error: null };
    }
    case ACTIONS.ADD_PARTICIPATION_SUCCEDED: {
      return {
        ...state,
        success: true,
        isLoading: false,
        item: payload.data,
        error: null,
      };
    }
    case ACTIONS.ADD_PARTICIPATION_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.FETCH_ALL_USERS_INIT: {
      return { ...state, isLoading: true, users: [], error: null };
    }
    case ACTIONS.FETCH_ALL_USERS_SUCCEDED: {
      return { ...state, users: payload.data, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_ALL_USERS_FAILED: {
      return { ...state, error: payload, isLoading: false, items: [] };
    }

    case ACTIONS.FETCH_ALL_PROGRAMS_INIT: {
      return { ...state, isLoading: true, activities: [], error: null };
    }
    case ACTIONS.FETCH_ALL_PROGRAMS_SUCCEDED: {
      return {
        ...state,
        activities: payload.data,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_ALL_PROGRAMS_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default participationReducer;
